<style>
.table_support td {
  text-align: left;
}
</style>
<template>
  <div class="row faq-row">
    <!--
    <div class="col-md-12" style="height: 160px; margin-bottom: 40px">
      <img
        src="../assets/images/banner_sub_notice.png"
        style="border-bottom: 2px solid #246180"
      />
    </div>
    -->
    <div class="col-md-offset-3 col-md-6" style="min-height: 500px">
      <h5 style="font-size: 30px; color: #103674">
        <span style="font-weight: 700">ChildU FAQ</span>
      </h5>
      <p>
        <span style="font-size: 15px">
          <span
            style="
              font-size: 18px;
              line-height: 22px;
              font-weight: 500;
              color: #103674;
            "
            >The same test score is not the same as what students don’t
            know.</span
          ><br />
          <span style="color: #666; line-height: 24px"
            >점수가 같다고 모르는 것이 같지 않습니다. 저희 ChildU는 미국
            그대로의 수업을 바탕으로 단순 시험용 영어가 아니라 진짜 영어를
            가르치고 있습니다.</span
          >
        </span>
      </p>
      <div style="margin-top: 20px">
        <div class="tab-content">
          <div class="cont_1">
            <div class="cont_title" style="margin-left: 2px"></div>

            <div style="padding-bottom: 50px">
              <table
                class="table_support"
                width="100%"
                border="0"
                cellspacing="0"
              >
                <colgroup>
                  <col width="80%" />
                  <col width="20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <span
                        class="q-word"
                        style="
                          color: #406890;
                          font-weight: 600;
                          line-height: 60px;
                        "
                        >Q</span
                      >&nbsp;&nbsp;<span
                        >1. [브랜드] ChildU는 어떤 브랜드인가요?
                      </span>
                      <div class="collapse" id="collapseExample1">
                        <div class="answer-txt">
                          차일드유는 미국 공통교육과정인 (CCSS) 핵심 교육 목표인
                          비판적, 창의적인 사고력을 가진 글로벌 인재 양성을
                          목표로 미국표준 발음과 현지 표현을 언어, 수학, 과학,
                          사회과목의 교과 지식과 함께 현지 학생들과 동일하게
                          습득할 수 있으며, 기초 파닉스 단계부터 미국 수능시험,
                          TOEFL 수준까지 온, 오프라인으로 체계적으로 학습할 수
                          있습니다.
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="answer-btn"
                        data-toggle="collapse"
                        href="#collapseExample1"
                        >답변보기</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        class="q-word"
                        style="
                          color: #406890;
                          font-weight: 600;
                          line-height: 60px;
                        "
                        >Q</span
                      >&nbsp;&nbsp;<span
                        >2. [브랜드] 다른 영어학원과의 차이점은
                        무엇인가요?</span
                      >
                      <div class="collapse" id="collapseExample2">
                        <div class="answer-txt">
                          차일드유는 급변하고 있는 영어 공교육 정책을 최적으로
                          대비할 수 있는 영어학원입니다.<br />
                          수능 외국어영역과 중학교 영어 내신이 절대평가로
                          시행되고 있는 상황에서 쓰기, 말하기의 비중은 계속해서
                          높아지고 있습니다.<br />
                          미국 공통교육과정(CCSS)을 다양한 강의 형태로 학생들이
                          학습하면서 학생의 비판 창의적인 독서 능력과 영어 세이
                          작성 능력을 체계적으로 향상할 수 있도록 특화된
                          영어학원입니다.
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="answer-btn"
                        ata-toggle="collapse"
                        href="#collapseExample2"
                        >답변보기</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        class="q-word"
                        style="
                          color: #406890;
                          font-weight: 600;
                          line-height: 60px;
                        "
                        >Q</span
                      >&nbsp;&nbsp;<span
                        >3. [입학] 입학 가능한 학년은 언제부터인가요?</span
                      >
                      <div class="collapse" id="collapseExample3">
                        <div class="answer-txt">
                          유치원 학생부터 고등학생까지 모든 레벨이 입학할 수
                          있습니다.<br />
                          커리큘럼이 미국 학년을 기반으로 구성되어 있어서 A, B,
                          C, D를 배우는 영어의 첫걸음부터 SAT, TOEFL 시험을
                          대비하는 수준까지 모두 등록이 가능합니다.<br />
                          학생은 입학 시 레벨 테스트를 통해 각자의 수준을
                          진단평가하고, 수준에 맞는 커리큘럼으로 체계적으로
                          학습하게 됩니다.
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="answer-btn"
                        data-toggle="collapse"
                        href="#collapseExample3"
                        >답변보기</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        class="q-word"
                        style="
                          color: #406890;
                          font-weight: 600;
                          line-height: 60px;
                        "
                        >Q</span
                      >&nbsp;&nbsp;<span
                        >4. [입학] 입학시험은 어떻게 보나요?</span
                      >
                      <div class="collapse" id="collapseExample4">
                        <div class="answer-txt">
                          입학시험은 한국 학생들의 연령별 평균 영어 듣기능력과
                          독해 능력을 평가하는 시험으로 L/C(듣기평가)와
                          R/C(독해평가) 시험으로 구성됩니다.<br />
                          모든 문항은 미국 교과과정의 Language Art 영역에서
                          요구하는 교과 지식과 영어 수준을 얼마나 잘 이해하고
                          있는지에 대해 평가합니다.<br />
                          시험은 PC에서만 시험이 가능하며, 학생의 영어 수준에
                          따라 총 6단계로 구성되며 Level 1~4단계는 L/C 10문항,
                          R/C 15문항으로 구성되며 소요 시간은 약 30분입니다.<br />
                          Level 5~6단계는 R/C만 33문항으로 구성되며 소요 시간은
                          약 40분입니다.<br />
                          테스트 결과를 바탕으로 신청하는 학생의 ID 레벨이
                          정해지며, 이 레벨에 따라 ID를 신청하게 됩니다.<br />
                          테스트는 예약된 일정에 따라 정해진 시간에 학원에서
                          실시하며, 테스트 완료 후 결과에 따른 피드백 상담이
                          제공됩니다.
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="answer-btn"
                        data-toggle="collapse"
                        href="#collapseExample4"
                        >답변보기</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        class="q-word"
                        style="
                          color: #406890;
                          font-weight: 600;
                          line-height: 60px;
                        "
                        >Q</span
                      >&nbsp;&nbsp;<span
                        >5. [시험] 시험은 어떤 유형으로 구성되나요?</span
                      >
                      <div class="collapse" id="collapseExample5">
                        <div class="answer-txt">
                          진단평가시험의 주기는 평균 3개월 단위로 측정하며
                          학원의 상황에 따라 달라질 수 있습니다.<br />
                          초급, 중급반을 위한 차일드유 시험은 학습 단계가
                          완료되는 마지막 시간에 학습한 내용에 대한 이해도를
                          평가하며, 테스트의 결과에 따라 재시험을 치게 될 수도
                          있습니다.<br />
                          시험 결과에 관한 결과 리포트와 전문적인 피드백 상담이
                          제공됩니다.
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="answer-btn"
                        data-toggle="collapse"
                        href="#collapseExample5"
                        >답변보기</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section_01">
    <div class="inner">
      <!--서브박스-->
      <!--#include virtual="/inc/sub_menu4.asp"-->

      <!--컨텐츠-->

      <!---아코디언--->
      <!--<button class="accordion">About ChildU</button>
		<div class="panel">
		   <ul class="sub-menu">
			   <li><a href="/sub/About ChildU/">브랜드소개</a></li>
			   <li><a href="/sub/About ChildU/">MY PATH</a></li>
			   <li><a href="/sub/About ChildU/myuse.asp">MY USE</a></li>
			   <li><a href="/sub/About ChildU/">URACLE</a></li>
			   <li><a href="/sub/About ChildU/">imagine learning</a></li>
		   </ul>
		</div>-->
    </div>
  </div>
</template>
