<style>
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #333;
  padding: 12px 40px;
  border-top: 3px solid #246180;
  border-radius: 0;
  border-bottom-color: none;
}

.table td {
  text-align: center;
  vertical-align: middle;
}

.table th {
  text-align: center;
  vertical-align: middle;
  background: #246180;
  color: #fff;
}

.bgcolor_font {
  color: #246180;
  font-weight: bold;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  vertical-align: middle;
}
</style>
<template>
  <div class="row">
    <div class="col-md-12" style="height: 160px; margin-bottom: 40px">
      <img
        src="../assets/images/banner_sub_info.png"
        style="border-bottom: 2px solid #246180"
      />
    </div>
    <div class="col-md-offset-3 col-md-6" style="min-height: 500px">
      <h5 style="font-size: 32px; color: #333">
        Mypath <span style="font-weight: bold">레벨테스트</span>
      </h5>

      <div
        class="col-md-12"
        style="
          margin-top: 10px;
          text-align: left;
          font-family: 'Noto Sans KR', sans-serif;
        "
      >
        <p>
          <span style="font-weight: bold; font-size: 18px"
            >본 테스트에서는
            <span style="color: #ffcc00">Language Arts 영역</span>을 평가</span
          >하여 학습자에게 적정한 학년(미국교과 기준)을 안내하고 있습니다.<br />
          학습하고자 하는 학년(미국기준)을 선택하여 시험을 보고나면, 각 문항의
          평가영역과 함께 시험 결과를 바로 확인 할 수 있습니다.
        </p>
        <h5
          style="
            font-size: 26px;
            color: #333;
            text-align: left;
            border-top: 1px dashed #ccc;
            padding: 14px;
            margin-bottom: 0;
            padding-left: 0;
          "
        >
          레벨테스트 <span style="font-weight: bold">권장레벨</span>
        </h5>
        <table class="table table-striped">
          <tr>
            <thead>
              <th>레벨</th>
              <th>영어 학습 수준</th>
              <th>권장 연령</th>
            </thead>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Phonics</span></td>
            <td>
              영어를 처음 접하거나, 알파벳 정도를 배운 학생(시험을 보지 않고
              Kindergarten과정을 학습)
            </td>
            <td>7세~9세</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Kindergarten</span></td>
            <td>Phonics가 자유롭고 story를 학습해야 하는 학생</td>
            <td>7세~10세</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 1</span></td>
            <td>영어를 2~3년 이상 학습한 학생</td>
            <td>7세~10세</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 2</span></td>
            <td>영어를 4년 이상 학습한 학생</td>
            <td>9세~15세</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 3</span></td>
            <td>영어를 5년 이상 학습한 학생</td>
            <td>10세~16세</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 4</span></td>
            <td>영어를 7년 이상 학습한 학생</td>
            <td>14세~17세</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 5</span></td>
            <td>영어를 8년 이상 학습한 학생</td>
            <td>15세~18세</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 6</span></td>
            <td>영어를 10년 이상 학습한 학생</td>
            <td>17세 이상</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 7</span></td>
            <td>외국 고등학교 혹은 대학교 진학을 목표로 하는 학생</td>
            <td>17세 이상</td>
          </tr>
          <tr>
            <td><span class="bgcolor_font">Grade 8</span></td>
            <td>외국 고등학교 혹은 대학교 진학을 목표로 하는 학생</td>
            <td>17세 이상</td>
          </tr>
        </table>

        <h5
          style="
            font-size: 26px;
            color: #333;
            text-align: left;
            border-top: 1px dashed #ccc;
            padding: 10px;
            padding: 14px;
            margin-bottom: 0;
            padding-left: 0;
          "
        >
          나에게 맞는 <span style="font-weight: bold">레벨테스트 시험보기</span>
        </h5>
        <p>Mypath Level Test는 학원에서만 보실 수 있습니다.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    map: null,
  }),
  methods: {},
};
</script>
