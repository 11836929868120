<style></style>
<template>
  <div class="row">
    <!--
    <div class="col-md-12" style="height: 160px; margin-bottom: 40px">
      <img
        src="../assets/images/banner_sub_notice.png"
        style="border-bottom: 2px solid #103674"
      />
    </div>
  -->
    <div class="col-md-offset-3 col-md-6">
      <h5 style="font-size: 30px; color: #103674">
        <h2 style="font-weight: 700; color: #103674; text-align: left">
          Campus GALLERY
        </h2>
      </h5>
      <p>
        <span style="font-size: 15px">
          <span
            style="
              font-size: 18px;
              line-height: 22px;
              font-weight: 500;
              color: #103674;
            "
            >The same test score is not the same as what students don’t
            know.</span
          ><br />
          <span style="color: #666; line-height: 24px"
            >점수가 같다고 모르는 것이 같지 않습니다. 저희 ChildU는 미국
            그대로의 수업을 바탕으로 단순 시험용 영어가 아니라 진짜 영어를
            가르치고 있습니다.</span
          >
        </span>
      </p>
      <div>
        <ul class="nav nav-tabs" style="color: #000">
          <li
            v-for="board in boards"
            :class="{ active: selBoard === board.id }"
            v-on:click="
              selBoard = board.id;
              loadBoard();
            "
            v-bind:key="board.id"
            style="font-size:20px;important;font-weight:bold;"
          >
            <a href="#" data-toggle="tab">{{ board.name }}</a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="home">
            <table class="table">
              <thead
                style="background: #fcfcfd; font-weight: bold; color: #333"
              >
                <tr>
                  <td>번호</td>
                  <td>제목</td>
                  <td>등록일</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="article in articles" v-bind:key="article.id">
                  <td>{{ article.id }}</td>
                  <td>
                    <router-link :to="'/articles/' + article.id">{{
                      article.title
                    }}</router-link>
                  </td>
                  <td>{{ article.created_at }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    boards: {},
    articles: [],
    selBoard: "",
  }),
  methods: {
    loadBoard() {
      axios
        .get(
          this.$root.baseUrl +
            "/articles_new?origin=usersite&boards=gaelleoli_website&limit=10&userid=" +
            this.$root.id
        )
        .then((response) => {
          if (response.data.error) {
            alert("게시글을 불러올 수 없습니다.\n" + response.data.error);
          } else {
            this.articles = response.data.result;
          }
        });
    },
  },
  mounted() {
    /*
          axios.get(this.$root.baseUrl + '/boards/' + this.$root.campus.id).then((response) => {
        if (response.data.error) {
          alert("게시판 리스트 로드 실패\n" + response.data.error)

        } else {
          this.boards = response.data.result;
        }


      })
*/
    this.boards = {
      "캠퍼스 갤러리": "gaelleoli_website",
    };
    this.selBoard = "gaelleoli_website";
    this.loadBoard();
  },
};
</script>
