
  <style>
  .row{font-family: "Noto Sans KR", "Malgun Gothic", "맑은 고딕", "Apple Gothic", "Apple SD Gothic Neo", sans-serif !important;color:#333;}
  .nav-tabs>li.active>a, .nav-tabs>li.active>a:focus, .nav-tabs>li.active>a:hover{ color:#333; padding:12px 40px; border-top:3px solid #246180;border-radius:0;;   border-bottom-color:none; }
  .table td{text-align:center; vertical-align:middle;}
  .table th{text-align:center; vertical-align:middle; background:#246180; color:#fff;}
  .bgcolor_font{color:#246180; font-weight:bold;}
  .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th{vertical-align: middle;}

  </style>
  <template>
    <div class="row">
      <div class="col-md-12" style="height:160px;  margin-bottom:40px; "><img src="../assets/images/banner_sub_info.png" style="border-bottom:2px solid #246180;"></div>
      <div class="col-md-offset-3 col-md-6" style="min-height:500px;">
        <h5 style="font-size:32px; color:#333; ">Mypath <span style="font-weight:bold;">수강신청하기</span></h5>

  <div class="col-md-12" style="margin-top:10px;text-align:left; font-family: 'Noto Sans KR', sans-serif;" ><p><span style="font-weight:bold;font-size:18px;">Mypath 상품은 <span style="color:#ffcc00;">월별로 결재</span>가 가능</span>하며 미국 본사와 동일한 학습 그대로를 제공해 드리고 있습니다.</p>
  <h5 style="font-size:26px; color:#333; text-align:left; border-top:1px dashed #ccc; padding:14px; margin-bottom:0; "> <span style="font-weight:bold;"></span></h5>
					<div style="display: flex; padding: 20px 0; ">
						<div class="" style="width: 25%; "><p style="color:#293977; font-size: 25px; font-weight: 700; text-align: left;">For K–2 Students</p>
							<p class="" style="text-align: left;">The K–2 learning experience includes larger buttons, icons, and action items to support early learners. It also includes audio supports for nonreaders. Students earn stars with ageappropriate rewards, and they can unlock new sidekicks and backgrounds to customize their experience.
							<img src="../assets/images/k-2_img.png" style="width: 90%;"></p>
						</div>   
						<div class="" style="width: 25%; "><p style="color:#293977; font-size: 25px; font-weight: 700; text-align: left;">For 3–5 Students</p>
						<p style="text-align: left;">The 3–5 learning experience provides age-appropriate rewards and motivation. Students have fun earning stars and unlocking new sidekicks and backgrounds as they level up.
							<img src="../assets/images/k-3_img.png" style="width: 90%;"></p>
						</div>
						<div class="" style="width: 25%; "><p style="color:#293977; font-size: 25px; font-weight: 700; text-align: left;">For 6–8 Students</p>
						<p style="text-align: left;">The middle school experience continues to adjust and adapt for older students with new backgrounds and sidekicks designed specifically for them. They will continue to earn stars and level up by completing lessons.
							<img src="../assets/images/k-6_img.png" style="width: 90%;"></p>
						</div>   
						<div class="" style="width: 25%; "><p style="color:#293977; font-size: 25px; font-weight: 700; text-align: left;">For 9–12 Students</p>
						<p style="text-align: left;">In the high school environment, the program shifts to a focus on completing the work, rejoining the general classroom, and graduating on time. Students can customize their experience in an age-appropriate, student-centered learning environment.
							<img src="../assets/images/k-12_img.png" style="width: 90%;"></p>
						</div>
					</div>
      
      
      
      
  <div class="card col-md-6 widget-content" v-for="product in products" v-bind:key="product.id">    
       
                                      <div class="widget-content-outer">
                                          <div class="widget-content-wrapper">
                                              <div class="col-md-6">
                                                  <div class="widget-heading">
                                                    <span v-if="product.product === 'MYPATH'">MyPath</span>
                                                    <span v-if="product.product === 'MYUSE'">MyUSE</span>
                                                     {{ product.period }}개월 수강권</div>
                                                  <div class="widget-subheading">Grade1 ~ Grade5 학습자에 한하면 북클럽과 보카조아는 추가구매 하셔야 합니다.</div>
                                              </div>
                                              <div class="col-md-6" style="text-align:center; padding:0px;vertical-align:middle;">
                                                  <div class="widget-numbers text-success" style="font-size:26px; line-height:32px;">{{ parseInt(product.price).toLocaleString() }}원<br>
                                                  <button class="btn btn-primary" v-if="this.$route.params.subscription" v-on:click="renewPurchase(product, this.$route.params.subscription)">결제하기</button>
                                                  <button class="btn btn-primary" v-if="!this.$route.params.subscription" v-on:click="newPurchase(product)">수강신청</button>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>

  </div>


  </div>
  </div>




</template>


<script>
import axios from 'axios'
export default {
  data: () => ({
    products: []
  }),
  methods: {
    mapLoaded(map) {
    }
  },
  mounted(){
        axios.get(this.$root.baseUrl + '/products').then((response) => {
        if (response.data.error) {
          alert("상품을 불러올 수 없습니다.\n" + response.data.error)

        } else {
          this.products = response.data.result;
        }


      })
  }
}
</script>
